/**
 *  override gnav registration toggle
    redirect to signin page and expand the registration section
 */

/* Initialize */
jQuery(function() {

    var $registrationSwitch = $('.js-register-switch');

    $registrationSwitch.click(function(e) {
        e.preventDefault();
        window.location = 'https://' + window.location.hostname + '/account/signin.tmpl?new_user=1';
    });

});
